.App .content {
    .whatsappContainer {
        flex: 1;
        width: 100%;
        max-width: 400px;
        padding: 4px;
        align-items: center;
        justify-content: center;
        @extend %flexColumn;        
        .WaMtple {
            padding:0px 3px;
            width: 100%;
            flex: 1;
            flex-direction: column;
            justify-content: center;
            @extend %flexColumn;
            .Header{
                @extend %flexColumn;
                flex: 0.2;
                align-items: center;
                justify-content: center;
                //border: 1px solid rgb(53, 48, 48);
                @extend %border1solid;
                @extend %flexRow;    
                box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.2);
                background: #00e676;
                border-radius: 6px;
                margin-bottom: 6px;
                div:nth-child(1) {
                    @extend %border1solid;
                    color:red;
                    flex: 0.3;
                    @extend %flexRow;  
                    justify-content: center;
                    align-items: center;
                    svg {
                        fill:white;
                    }
                }
                div:nth-child(2) {
                    @extend %border1solid;
                    color:red;
                    flex: 1;
                    p {
                        color:white;
                    }
                }
            }
            .Body{
                flex: 1;
                align-items: center;
                @extend %border1solid;
                @extend %flexColumn;
                overflow: auto;
                max-height: 60vh;
                padding: 1px 0px;
                ul {
                    flex: 1;
                    width: 98%;
                    a {
                        text-decoration: none;
                        /* font-weight: bold; */
                        
                        li {
                            @extend %flexColumn;
                            height: 18%;
                            flex-direction: row !important;
                            padding: 6px;
                            flex: 1;
                            list-style: none;
                            border-left: 2px solid #00e676;
                            background: #f5f7f9;
                            border-radius: 4px;
                            box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.2);
                            align-items: center;
                            justify-content: space-between;
                            margin-top: 12px;
                            div {
                                
                                @extend %border1solid;
                                &:nth-child(1) {
                                    color:red;
                                    flex: 0.8;
                                }
                                &:nth-child(2) {
                                    color:red;
                                    flex: 3;
                                }
                                &:nth-child(3) {
                                    color:red;
                                    flex: 0.4;
                                    align-items: right;
                                    justify-content: right;
                                }
                            }                         
    
                            img.WaMtpleFoto {
                                /* @extend %flexColumn; */
                                border: 1px solid #00e676;
                                width: 50px;
                                height: 50px;
                                max-width: 50px;
                                max-height: 50px;
                                border-radius: 100px;
                                background:#00e676;
                                margin-left: 3.5%;
                                margin-right: 10px;
                            }
                            p.WaMtpleNome {
                                font-family: 'Ubuntu', sans-serif;
                                font-weight: 400;
                                /* @media only screen and (max-width: 360px) {
                                    margin-left: -4.4em;
                                }
                                @media only screen and (max-width: 320px) and (min-width: 320px) {
                                    color:red;
                                    margin-left: -3em;
                                } */
                            }
                            .WaMtpleIcon {
                                fill:#00e676;
                                margin-right: 3%;
                            }
                        }
                    }
                    
                }
            }
        }

        .WaStalo {
            @extend .WaMtple;
            .Header {
                @extend .Header;
                flex: 0.1 !important;
                background:#044a41;
                border-radius: 6px;

                div:nth-child(1) {
                   flex:0.3;
                   img.WaStaloFoto {
                        border: 1px solid white;
                        width: 50px;
                        height: 50px;
                        max-width: 50px;
                        max-height: 50px;
                        border-radius: 100px;
                        background: white;
                        margin-left: 3.5%;
                        margin-right: 10px;
                   }
                }
                div:nth-child(2) {
                    flex:1; 
                }
                div:nth-child(3) {
                    flex:0.2;
                    .WaSendStaloIcon {
                        fill:#FFF;
                        margin-right: 8%;
                    }
                }
            }

            .Body {
                @extend .Body;
                flex: 1;
                max-height: 70vh;
                background: url('https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png');
                background-size: cover;
                border-radius: 6px;
                .BodyOla {
                    @extend %border1solid;
                    @extend %flexColumn;
                    width: 100%;
                    flex: 1;
                    p{
                        background: white;
                        padding:8px;
                        margin: 10px 18px;
                        border-radius: 0px 6px 6px 6px;
                        /* width: max-content; */
                        //box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.2);
                        position: relative;
                        &:after {
                            position: absolute;
                            content: "";
                            width: 0;
                            height: 0;
                            border-style: solid;
                            border-width: 0px 10px 10px 0;
                            border-color: transparent #fff transparent transparent;
                            top: 0;
                            left: -10px;
  
                        }
                    }
                }
                .BodyInput {
                    @extend %border1solid;
                    @extend %flexRow;
                    width: 100%;
                    flex: 0.14;
                    padding:8px;
                    align-items: center;
                    justify-content: center;
                    div:nth-child(1) {
                        @extend %border1solid;
                        @extend %flexColumn;
                        flex: 1;
                        margin-right:8px;

                        input {
                            @extend %flexColumn;
                            border-radius: 6px;
                            border: none;
                            width: 100%;
                            height: 50px;
                            padding:10px;
                            font-size:18px;
                            color:#044a41;
                            background:rgba(255,255,255,0.6);
                            box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.2);
                        }
                    }
                    div:nth-child(2) {
                        @extend %border1solid;
                        @extend %flexColumn;
                        //flex: 0.2;
                        border-radius: 100px;
                        background:#044a41;
                        align-items: center;
                        justify-content: center;
                        margin:0px;
                        width: 40px !important;
                        height: 40px;
                        .WaStaloIcon {
                            fill:#FFFFFF;
                            margin-right: 3%;
                            margin-top: 12%;
                        }
                    }
                }
                

            }
        }
    }
}