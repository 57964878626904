$max-width: 420px;

.App {
  width: 100vw;
  max-width: $max-width;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  background-size: cover;


  .header {
    width: 100vw;
    max-width: $max-width;
    display: flex;
    height: 10vh;
    align-items: center;
    justify-content: center;
    img.headerLogo {
      height: 5vh;
    }
  }

  .content {
    width: 100vw;
    max-width: $max-width;
    display: flex;
    flex: 1;
    padding: 8px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .nav {
    width: 100vw;
    max-width: $max-width;
    display: flex;
    height: 8vh;
    //border-radius: 200px;
    background: rgb(236, 236, 236);
    box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.2);
    margin: 0px;
    

    a.link {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      margin: 4px;
      opacity: 0.8;
      
      //border-radius: 200px;
      text-decoration: none;
      
      
      svg {
        fill: rgba(100, 100, 100, 0.6);
        stroke: black !important;
          stroke-width: 2 !important;
        path {
          stroke: black !important;
          stroke-width: 2 !important;
        }
      }
    }

    /* a.home {
      transform:scale(1.4);
    } */

    .linkActive{
      //background: green !important;
      opacity: 1;
      svg {
        //color: red !important;
        fill: rgba(100, 100, 100, 0.9) !important;
        transform:scale(1.3,1.3);
      }
    }

  }
}



