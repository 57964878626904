%flexColumn {
    display: flex;
    flex-direction: column;
}

%flexRow {
    display: flex;
    flex-direction: row;
}

%border1solid {
    border: 0px solid red;
}
