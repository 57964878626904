@import './scss/app', './scss/colors', './scss/root', './scss/whatsapp', './scss/extendsmixins';

@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');

* {
  color:var(--txt);
  //border: 1px solid red;
  box-sizing: border-box;
  transition: all 1s  cubic-bezier(.1,.84,.37,.34);
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0); 
  font-family: 'Ubuntu', sans-serif;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
    outline-width: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--bg);

  main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }


  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  
}

a:hover, a:active, a:focus {
  outline: 0;
  border: none;
  -moz-outline-style: none;
  outline-style: none;
}

.logoLoading {
  @extend %flexColumn;
  align-items: center;
  justify-content: center;
  flex: 1;
  /* border: 1px solid red; */
  width: 100vw;
  height: 100vh;
  z-index: 999;
  position: absolute;
  background: white;
}

