:root {
    --bg: #{$l-bg}; //#{$c1};
    --txt: #{$l-txt};
    --h1: #{$l-h1};
  }
  
  :root[data-theme='dark'] {
    --bg: #{$d-bg};
    --txt: #{$d-txt};
    --h1: #{$d-h1};
  }